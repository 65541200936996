import "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import React from "react";
import { Provider } from "./src/context";

// Contextの読込
export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>;

import React, { createContext, FC, useState } from "react";

interface ProgressContextProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialProgressState: ProgressContextProps = {
  isLoading: false,
  setIsLoading: () => {},
};

export const ProgressContext = createContext<ProgressContextProps>(
  initialProgressState
);
export const ProgressConsumer = ProgressContext.Consumer;

export const ProgressProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <ProgressContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

import React, { createContext, FC, useState } from "react";
import { User } from "../domain/model/user";

interface AuthContextProps {
  user?: User;
  setUser: (user?: User) => void;
}

const initialAuthState: AuthContextProps = {
  setUser: () => {},
};

export const AuthContext = createContext<AuthContextProps>(initialAuthState);

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User>();

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import React, { FC } from "react";
import { AuthProvider } from "./auth_context";
import { SnackbarProvider } from "notistack";
import { ProgressProvider } from "./progress";

export const Provider: FC = ({ children }) => {
  return (
    <AuthProvider>
      <ProgressProvider>
        <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
      </ProgressProvider>
    </AuthProvider>
  );
};
